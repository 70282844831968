'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  GooglePlacesInput,
  TagsSelector,
  Checkbox,
  FormDescription,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui';
import { ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import { useMask } from '@react-input/mask';
import { DialogFooter } from '@/components/ui/dialog';
import { DATE_MASK, FREQUENCY, HOURS_FREQUENCY, LANGUAGES_TAGS, PROGRAM_NUMBERS } from '@/common/constants';
import { createServiceValidationSchema, CreateServiceValidationSchema } from './create-service-validation-schema';
import { useCreateServiceMutation } from '@/hooks/fetchers/mutations/services/useCreateServiceMutation';
import { useClientsQuery } from '@/hooks/fetchers/queries/clients/useClientsQuery';
import { TagItem } from '@/components/form/tags-selector/tags-selector';
import { Client, Guardian } from '@/views/all-clients/interfaces/clients.interface';
import { useServiceRolesQuery } from '@/hooks/fetchers/queries/service-roles/useServiceRolesQuery';
import { useEffect, useState } from 'react';
import { ServiceRole } from '@/views/service-roles/interfaces/service-role.interface';
import * as _ from 'lodash';
import { useTherapistsQuery } from '@/hooks/fetchers/queries/therapists/useTherapistsQuery';
import { Therapist } from '@/views/all-therapists/interfaces/therapists.interface';
import dayjs from '@/lib/dayjsConfig';
import { SPECIALITY_CODES } from '@/common/enums';

const LANGUAGES = LANGUAGES_TAGS();

interface Props {
  onCancel?: () => void;
  onRefresh: () => void;
}

export function CreateServiceForm({ onCancel, onRefresh }: Props) {
  const t = useTranslations();
  const [languagesTagsTrigger, setLanguagesTagsTrigger] = useState<boolean>(false);
  const inputAuthorizedToRef = useMask({ ...DATE_MASK });
  const inputAuthorizedFromRef = useMask({ ...DATE_MASK });
  const [keywordClient, setKeywordClient] = useState('');
  const [regionalCenterFilter, setRegionalCenterFilter] = useState();
  const [keywordServiceRole, setKeywordServiceRole] = useState('');
  const [keywordTherapist, setKeywordTherapist] = useState('');

  const {
    data: clients,
    isLoading: isLoadingClients,
  } = useClientsQuery<TagItem[]>(
    {
      search: keywordClient,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((client: Client) => ({ label: `${client.firstName} ${client.lastName}`, value: client })),
    }
  );

  const {
    data: serviceRoles,
    isLoading: isLoadingServiceRoles,
  } = useServiceRolesQuery<TagItem[]>(
    {
      regionalCenterFilter,
      search: keywordServiceRole,
      skip: 0,
      take: 200
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((serviceRole: ServiceRole) => ({ label: serviceRole.title, value: serviceRole })),
    }
  );

  const {
    data: therapists,
    isLoading: isLoadingTherapists,
  } = useTherapistsQuery<TagItem[]>(
    {
      search: keywordTherapist,
      skip: 0,
      take: 200
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((therapist: Therapist) => ({
          label: `${therapist.firstName} ${therapist.lastName}`,
          value: therapist,
        })),
    }
  );

  const onSearchByClients = (value: string) => {
    setKeywordClient(value);
  }

  const onSearchByTherapists = (value: string) => {
    setKeywordTherapist(value);
  }

  const onSearchByServiceRoles = (value: string) => {
    setKeywordServiceRole(value);
  }

  const { mutate: create, isPending: isCreating } = useCreateServiceMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.serviceCreated'),
      });
      form.reset();
      onRefresh();
    },
  });

  const form = useForm<CreateServiceValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(createServiceValidationSchema),
    defaultValues: {
      client: '',
      serviceRole: '',
      title: '',
      guardians: [],
      authorizedTo: '',
      authorizedFrom: '',
      address: '',
      languages: [],
      jobRoleType: '',
      monthly: 0,
      frequency: '',
      numberOfHours: '',
      therapist: '',
      inPersonOnly: false,
      geofencing: false,
    },
  });

  const clientSelector = form.watch('client');
  const serviceRoleSelector = form.watch('serviceRole');
  const frequencySelector = form.watch('frequency');
  const numberOfHoursSelector = form.watch('numberOfHours');

  useEffect(() => {
    const { client } = form.getValues();
    if (client) {
      const clientValues = JSON.parse(client);
      if (clientValues.regionalCenter) {
        setRegionalCenterFilter(clientValues.regionalCenter);
      }
    }
  }, [clientSelector]);

  useEffect(() => {
    const { client, serviceRole } = form.getValues();
    if (client && serviceRole) {
      const clientValues = JSON.parse(client);
      // setRegionalCenterFilter()
      const serviceRoleValues = JSON.parse(serviceRole);

      const guardiansByClient: TagItem[] = clientValues.guardians.map((guardian: Guardian) => ({
        label: `${guardian.firstName} ${guardian.lastName}`,
        value: guardian,
      }));

      const allGuardiansLanguages: string[] = clientValues.guardians.map((guardian: Guardian) => guardian.languages);
      const languagesByGuardian: TagItem[] = _.uniq(_.flatten(allGuardiansLanguages)).map((lang: string) => ({
        label: lang,
        value: { id: lang },
      }));

      form.setValue('title', `${clientValues.lastName}, ${clientValues.firstName} ${serviceRoleValues.speciality.code}`);
      form.setValue('address', clientValues.address ?? '');
      form.setValue('languages', languagesByGuardian);
      form.setValue('jobRoleType', serviceRoleValues.jobRoleType.name);
      form.setValue('authorizedTo', dayjs(clientValues.dob, 'YYYY-MM-DD').add(3, 'year').format('MM.DD.YYYY'));
      form.setValue('guardians', guardiansByClient);
      form.clearErrors('title');
      form.clearErrors('authorizedTo');
      form.clearErrors('address');
      form.clearErrors('languages');
      
      const code = serviceRoleValues.speciality.code;
      if (code === SPECIALITY_CODES.CDS) {
        form.setValue('inPersonOnly', true);
      } else {
        form.setValue('inPersonOnly', false);
      }

      setLanguagesTagsTrigger((prev) => (prev = !prev));
    }
  }, [clientSelector, serviceRoleSelector]);

  useEffect(() => {
    const { frequency, numberOfHours } = form.getValues();
    if (frequency && numberOfHours) {
      const numberOfHoursValues = parseInt(numberOfHours, 10);
      form.setValue('monthly', frequency === 'week' ? numberOfHoursValues * 5 : numberOfHoursValues);
    }
  }, [frequencySelector, numberOfHoursSelector]);


  const onSubmit: SubmitHandler<CreateServiceValidationSchema> = async (value: CreateServiceValidationSchema) => {
    create(value);
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <ScrollArea className="h-[calc(100vh-300px)] overflow-auto">
          <div className="ml-1 grid grid-cols-form-cols-2 justify-start gap-8">
            <div className="relative">
              <FormField
                control={form.control}
                name="client"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.client.label')}</FormLabel>
                    <FormControl>
                      <Select
                        onOpenChange={() => setKeywordClient('')}
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue
                              placeholder={isLoadingClients ? t('Common.loadingWait') : t('Forms.client.placeholder')}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent search onChangeSearch={onSearchByClients}>
                          {field.value && (
                            <SelectItem
                              value={field.value}
                            >{`${JSON.parse(field.value).firstName} ${JSON.parse(field.value).lastName}`}</SelectItem>
                          )}

                          {clients
                            ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                            .map((item) => (
                              <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                                {item.label}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="serviceRole"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.serviceRole.label')}</FormLabel>
                    <FormControl>
                      <Select
                        onOpenChange={() => setKeywordTherapist('')}
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue
                              placeholder={
                                isLoadingServiceRoles ? t('Common.loadingWait') : t('Forms.serviceRole.placeholder')
                              }
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent search onChangeSearch={onSearchByServiceRoles}>
                          <SelectItem value={null as any}>None</SelectItem>
                          {field.value && field.value !== '' ? (
                            <SelectItem value={field.value}>{JSON.parse(field.value).title}</SelectItem>
                          ) : null}

                          {serviceRoles
                            ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                            .map((item) => (
                              <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                                {item.label}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="relative">
            <FormField
              control={form.control}
              name="inPersonOnly"
              render={({ field }) => (
                <FormItem className="my-4 mt-8 flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel className="cursor-pointer">{t('Forms.inPersonOnly.label')}</FormLabel>
                    <FormDescription>{t('Forms.inPersonOnly.description')}</FormDescription>
                  </div>
                </FormItem>
              )}
            />
          </div>

          <div className="relative">
            <FormField
              control={form.control}
              name="geofencing"
              render={({ field }) => (
                <FormItem className="my-4 mt-8 flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel className="cursor-pointer">{t('Forms.geofencing.label')}</FormLabel>
                    <FormDescription>{t('Forms.geofencing.description')}</FormDescription>
                  </div>
                </FormItem>
              )}
            />
          </div>

          <h3 className="mb-4 mt-6 text-base font-semibold text-gray-900">
            {t('Pages.AllServices.additionalDetails')}
          </h3>

          <div className="ml-1 grid grid-cols-form-cols-2 gap-8">
            <div className="relative">
              <FormField
                control={form.control}
                name="title"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.title.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="text"
                        placeholder={t('Forms.title.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="guardians"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.guardians.label')}</FormLabel>
                    <FormControl>
                      <TagsSelector
                        disabled
                        values={field.value ? (field.value as TagItem[]) : []}
                        onChoose={field.onChange}
                        placeholder={t('Forms.guardians.label')}
                        className={cn('pointer-events-none', !!fieldState.error && 'border-destructive')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="authorizedFrom"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.authorizedFrom.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        ref={inputAuthorizedFromRef}
                        type="text"
                        placeholder={t('Forms.authorizedFrom.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="authorizedTo"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.authorizedTo.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        ref={inputAuthorizedToRef}
                        type="text"
                        placeholder={t('Forms.authorizedTo.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="programNumber"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.programNumber.label')}</FormLabel>
                    <FormControl>
                      <Select value={field.value?.toString()} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue placeholder={t('Forms.programNumber.placeholder')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value={null as any}>None</SelectItem>
                          {PROGRAM_NUMBERS.map((item) => (
                            <SelectItem key={item} value={item.toString()}>
                              {item}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="address"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.address.label')}</FormLabel>
                    <FormControl>
                      <GooglePlacesInput
                        values={field.value}
                        onChanges={field.onChange}
                        className={cn('', !!fieldState.error && 'border-destructive')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="therapist"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.therapist.label')}</FormLabel>
                    <FormControl>
                      <Select
                        onOpenChange={() => setKeywordTherapist('')}
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue
                              placeholder={
                                isLoadingTherapists ? t('Common.loadingWait') : t('Forms.therapist.placeholder')
                              }
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent search onChangeSearch={onSearchByTherapists}>
                          <SelectItem value={null as any}>None</SelectItem>
                          {field.value && field.value !== '' ? (
                            <SelectItem
                              value={field.value}
                            >{`${JSON.parse(field.value).firstName} ${JSON.parse(field.value).lastName}`}</SelectItem>
                          ) : null}

                          {therapists
                            ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                            .map((item) => (
                              <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                                {item.label}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <div className="flex flex-row gap-1">
                <FormField
                  control={form.control}
                  name="numberOfHours"
                  render={({ field, fieldState }) => (
                    <FormItem className="w-full">
                      <FormLabel isRequired>{t('Forms.numberOfHours.label')}</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={field.onChange}>
                          <FormControl>
                            <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                              <SelectValue placeholder={t('Forms.numberOfHours.placeholder')} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {HOURS_FREQUENCY.map((item) => (
                              <SelectItem key={item} value={item.toString()}>
                                {item}h
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="frequency"
                  render={({ field, fieldState }) => (
                    <FormItem className="w-full">
                      <FormLabel isRequired>{t('Forms.frequency.label')}</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={field.onChange}>
                          <FormControl>
                            <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                              <SelectValue placeholder={t('Forms.frequency.placeholder')} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {FREQUENCY.map((item) => (
                              <SelectItem key={item} value={item}>
                                {item}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="absolute" />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="jobRoleType"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.type.label')}</FormLabel>
                    <FormControl>
                      <Input
                        disabled
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="text"
                        placeholder={t('Forms.type.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="monthly"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.monthly.label')}</FormLabel>
                    <FormControl>
                      <Input
                        disabled
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="text"
                        placeholder={t('Forms.monthly.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
            <div className="relative">
              <FormField
                control={form.control}
                name="languages"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.languages.label')}</FormLabel>
                    <FormControl>
                      <TagsSelector
                        data={LANGUAGES}
                        values={(field.value ? field.value : []) as TagItem[]}
                        trigger={languagesTagsTrigger}
                        onChoose={field.onChange}
                        placeholder={t('Forms.languages.label')}
                        className={cn('', !!fieldState.error && 'border-destructive')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </ScrollArea>

        <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
          <Button onClick={onCancel} variant="ghost" type="button" size="lg" className="mt-5">
            {t('Buttons.cancel')}
          </Button>

          <Button type="submit" size="lg" className="mt-5" disabled={isCreating}>
            {t('Buttons.save')}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
