import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import dayjs from '@/lib/dayjsConfig';
import { Colors, SPECIALITY_CODES } from '@/common/enums';
import { SPECIALITY_COLORS } from '@/common/constants/chips-colors';
import { MONTHS } from '@/common/constants/common';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatFileSize(bytes: number, decimalPoint: number): string {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function capitalizeFirstLetter(val: string) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export const isSafari = (): boolean => {
  if (typeof navigator === 'undefined') return false; // Ensure it's client-side
  const userAgent = navigator.userAgent;
  return /^((?!chrome|android).)*safari/i.test(userAgent);
};

export function minutesToTime(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  
  // Pad hours and minutes with leading zeros if needed
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(mins).padStart(2, '0');

  return `${formattedHours}h ${formattedMinutes}m`;
}

export function convertHoursToTime(hours: number): string {
  const h = Math.floor(hours); 
  const m = Math.round((hours - h) * 60); 
  return `${h}h ${m.toString().padStart(2, '0')}m`;
}

export function shortNumber(num: any): string {
  num = num.toString().replace(/[^0-9.]/g, '');
  if (num < 1000) {
    return num;
  }
  let si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' },
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
}

export function transformViewDateToApiDate(date?: string): string {
  return date ? dayjs.utc(date, 'MM.DD.YYYY').format('YYYY-MM-DD') : '';
}

export function transformApiDateToViewDate(date?: string): string {
  return date ? dayjs.utc(date, 'YYYY-MM-DD').format('MM.DD.YYYY') : '';
}

export function transformDateToViewDate(date?: string, isUTC: boolean = true): string {
  try {
    if (date && dayjs(date).isValid()) {
      if (isUTC) {
        return dayjs.utc(date).format('MM.DD.YYYY');
      } else {
        return dayjs(date).format('MM.DD.YYYY');
      }
    }
    return '';
  } catch (error) {
    return '';
  }
}

export function transformDateToTime(date?: string): string {
  try {
    if (date && dayjs(date).isValid()) {
      return dayjs(date).utcOffset(dayjs().format('Z')).format('h:mm A');
    }
    return '';
  } catch (error) {
    return '';
  }
}

export function openAddressInGoogleMaps(address?: string): void {
  if (address) {
    const link = `https://www.google.com/maps/search/?api=1&query=${address}`;
    window.open(link, '_blank', 'noopener,noreferrer');
  }
}

export function generateTimeSlots(startHours = 0): string[] {
  const times: string[] = [];
  let currentDate = new Date();
  currentDate.setHours(startHours, 0, 0, 0); // Start at night

  for (let i = 0; i < 24 * 60; i += 5) {
    // 24h, each step - 5m
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';

    // h:mm a
    const formattedTime = `${hours % 12 === 0 ? 12 : hours % 12}:${minutes.toString().padStart(2, '0')} ${period}`;
    times.push(formattedTime);

    // Increase at 5m
    currentDate.setMinutes(currentDate.getMinutes() + 5);
  }

  return times;
}

export function extractFirstPart(text: string): string | null {
  const match = text.match(/^\S+/);
  return match ? match[0] : null;
}

export function getColorForServiceRole(title?: string, code?: SPECIALITY_CODES): Colors {
  const _code = code ? code : title ? (extractFirstPart(title) as SPECIALITY_CODES) : null;
  return _code ? (SPECIALITY_COLORS[_code] ? SPECIALITY_COLORS[_code] : Colors.green) : Colors.green;
}

export function compileFullName<
  T extends {
    firstName: string;
    lastName: string;
    deactivatedAt?: string;
  },
>(value?: T): string {
  if (!value) {
    return '---';
  }
  const fn = value.firstName ?? '';
  const ln = value.lastName ?? '';
  return `${fn} ${ln} ${value.deactivatedAt ? '(Deleted)' : ''}`;
}

export function compileLastName<
  T extends {
    lastName: string;
    deactivatedAt?: string;
  },
>(value?: T): string {
  if (!value) {
    return '---';
  }
  const ln = value.lastName ?? '';
  return `${ln} ${value.deactivatedAt ? '(Deleted)' : ''}`;
}

export function generateLast6Months(): string[] {
  const months = Object.keys(MONTHS);
  const result: string[] = [];
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);

  for (let i = 0; i < 6; i++) {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
    const monthName = months[date.getMonth()];
    const year = date.getFullYear();
    result.push(`${monthName} (${year})`);
  }

  return result;
}

export function parseMonthYear(value: string): string {
  const match = value.match(/^([a-zA-Z]+) \((\d{4})\)$/);
  if (!match) {
    throw new Error("Invalid format. Expected 'Month (YYYY)'.");
  }

  const [_, month, year] = match;
  const monthNumber = (MONTHS as {[index: string]: number})[month];

  if (!monthNumber) {
    throw new Error("Invalid month name.");
  }
  return `${year}-${monthNumber}`;
}

export function getPreviousMonthFromLocalDate(): string {
  const currentDate = new Date(); 
  const date = new Date(currentDate.getFullYear(), currentDate.getMonth()); 

  date.setMonth(date.getMonth() - 1); 

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return `${year}-${month}`;
}
