import { ColumnDef, Row } from '@tanstack/react-table';
import { toMaskByString } from '@/components/form/phone-input/utils';
import { cn, getColorForServiceRole } from '@/lib/utils';
import { ServiceRole, Therapist } from '../interfaces/therapists.interface';
import { Trash2 } from 'lucide-react';
import { Chips } from '@/components/common';
import { Colors } from '@/common/enums';
import { CollapsibleColumn } from '@/components/common/table/collapsible-column';
import { USER_ROLES_COLORS } from '@/common/constants/chips-colors';

type Params = {
  t: any;
  onConfirmRemove: (row: Row<Therapist>) => void;
  isCanRemove?: boolean;
}

export const getAllTherapistsColumns = ({t, onConfirmRemove, isCanRemove = true}: Params): ColumnDef<Therapist>[] => {
  const columns: ColumnDef<Therapist>[] = [
    {
      id: 'Full Name',
      accessorFn: (row) => `${row.firstName || ''} ${row.lastName || ''}`,
      header: () => <span>{t('Tables.fullName')}</span>,
      size: 250,
      enablePinning: false
    },
    {
      id: 'Email',
      accessorKey: 'email',
      enableSorting: false,
      header: () => <span>{t('Tables.email')}</span>,
      size: 250,
      enablePinning: false,
    },
    {
      id: 'Phone Number',
      enableSorting: false,
      accessorFn: (row) => toMaskByString(row.phone),
      header: () => <span>{t('Tables.phoneNumber')}</span>,
      size: 170,
      enablePinning: false,
    },
    {
      id: 'Service Roles',
      header: () => <span>{t('Tables.serviceRoles')}</span>,
      accessorKey: 'serviceRole',
      cell: ({ row }) => {
        if (row.original.serviceRole) {
          // TODO: Collapsible logic
          const visible = row.original.serviceRole.slice(0, 1);
          const hidden = row.original.serviceRole.slice(1);

          const parseFn = (item: ServiceRole) => ({
            id: item.id,
            title: item.title,
            color: getColorForServiceRole(item.title, item.speciality ? item.speciality.code : undefined),
          });

          return (
            <CollapsibleColumn
              visible={visible.map(parseFn)}
              hidden={hidden.map(parseFn)}
            />
          );
          // return (
          //   <div className="flex flex-row flex-wrap gap-1 py-2">
          //     {row.original.serviceRole.map((role) => (
          //       <Chips color={getColorForServiceRole(role.title, role.speciality ? role.speciality.code : undefined)} key={role.id} title={role.title} />
          //     ))}
          //   </div>
          // );
        } 
        return <></>;
      },
      size: 250,
      enablePinning: false,
    },
    {
      id: 'Language',
      enableSorting: false,
      header: () => <span>{t('Tables.language')}</span>,
      cell: ({ row }) => {
        if (row.original.languages) {
          return <span className=''>{row.original.languages.join(', ')}</span>;
        } else {
          return <span></span>;
        }
      },
      size: 90,
      enablePinning: false,
    },
    {
      id: 'Access Level',
      enableSorting: false,
      header: () => <span>{t('Tables.accessLevel')}</span>,
      cell: ({ row }) => {
        if (row.original.role) {
          return <Chips color={USER_ROLES_COLORS[row.original.role]} title={row.original.role} />;
        } else {
          return <></>;
        }
      },
      size: 250,
      enablePinning: false,
    }
  ];

  if (isCanRemove) {
    columns.push({
      id: 'Remove',
      enableSorting: false,
      cell: ({ row }) => (
        <Trash2
          onClick={(e) => {
            if (!row.original.deactivatedAt) {
              onConfirmRemove(row);
            }
            e.stopPropagation();
          }}
          className={cn("w-[18px] cursor-pointer text-gray-400", row.original.deactivatedAt && 'opacity-45 cursor-not-allowed')}
        />
      ),
      size: 50,
    });
  }
  return columns;
};
