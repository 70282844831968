import { ColumnDef } from '@tanstack/react-table';
import dayjs from '@/lib/dayjsConfig';
import { AvailableClient } from '../interfaces/available-clients.interface';
import { Chips } from '@/components/common';
import { Colors } from '@/common/enums';
import { compileFullName, getColorForServiceRole, transformDateToViewDate } from '@/lib/utils';
import { USER_ROLES_COLORS } from '@/common/constants/chips-colors';
import { RoleTypes } from '@/lib/RBAC/enums/role-types';

type Params = {
  t: any;
};

export const getAvailableClientsColumns = ({ t }: Params): ColumnDef<AvailableClient>[] => {
  return [
    {
      id: 'Title',
      accessorKey: 'title',
      header: () => <span>{t('Tables.title')}</span>,
      size: 250, //starting column size
    },
    {
      id: 'Client',
      header: () => <span>{t('Tables.client')}</span>,
      cell: ({ row }) => {
        if (row.original.client) {
          return <Chips color={Colors.indigo} title={compileFullName(row.original.client)} />;
        }
        return <></>;
      },
      size: 250,
      enableSorting: false,
    },
    {
      id: 'Service Role',
      header: () => <span>{t('Tables.serviceRole')}</span>,
      cell: ({ row }) => {
        if (row.original.serviceRole) {
          const sr = row.original.serviceRole;
          return <Chips color={getColorForServiceRole(sr.title, sr.speciality.code)} title={sr.title} />;
        }
        return <></>;
      },
      enableSorting: false,
      size: 250,
    },
    {
      id: 'Guardian',
      accessorKey: 'type',
      header: () => <span>{t('Tables.guardian')}</span>,
      cell: ({ row }) => {
        if (row.original.client && row.original.client.guardians) {
          return (
            <div className="flex flex-row flex-wrap gap-1 py-2">
              {row.original.client.guardians.map((guardian) => (
                <Chips key={guardian.id} color={USER_ROLES_COLORS[RoleTypes.Guardian]} title={compileFullName(guardian)} />
              ))}
            </div>
          );
        }
        return <></>;
      },
      enableSorting: false,
      size: 250,
    },
    {
      id: 'Frequency',
      header: () => <span>{t('Tables.frequency')}</span>,
      accessorFn: (row) => `${row.hours}h/${row.frequency}`,
      enableSorting: false,
      size: 140,
    },
    {
      id: 'Authorized To',
      accessorKey: 'authorizedTo',
      accessorFn: (row) => transformDateToViewDate(row.authorizedTo, false),
      header: () => <span>{t('Tables.authorizedTo')}</span>,
      enableSorting: false,
      size: 120,
    },
    {
      id: 'Languages',
      header: () => <span>{t('Tables.languages')}</span>,
      cell: ({ row }) => {
        if (row.original.languages) {
          return <span className="">{row.original.languages.join(', ')}</span>;
        } else {
          return <span></span>;
        }
      },
      enableSorting: false,
      size: 100,
    },
    {
      id: 'Address',
      accessorKey: 'address',
      header: () => <span>{t('Tables.address')}</span>,
      enableSorting: false,
      size: 250,
    },
  ];
};
