export enum PermissionsTypes {
  /**
   * Admin Management:
   * Permission to view the list of admin users.
   */
  VIEW_ADMIN_USER_LIST,
  /**
   * Admin Management:
   * Permission to create a new admin.
   */
  CREATE_NEW_ADMIN,
  /**
   * Admin Management:
   * Permission to view an admin profile.
   */
  VIEW_ADMIN_PROFILE,
  /**
   * Admin Management:
   * Permission to modify an admin profile.
   */
  CHANGE_ADMIN_PROFILE,
  /**
   * Admin Management:
   * Permission to soft delete an admin user.
   */
  RESEND_INVITATION_LINK,
  /**
   * Admin Management:
   * Resend invitation link to users.
   */
  SOFT_DELETE_ADMIN_USER,
  /**
   * Therapists Management:
   * Permission to create a therapist account.
   */
  CREATE_THERAPIST,
  /**
   * Therapists Management:
   * Permission to set the pay rate.
   */
  SET_PAY_RATE,
  /**
   * Therapists Management:
   * Permission to view all therapists.
   */
  VIEW_ALL_THERAPISTS,
  /**
   * Therapists Management:
   * Permission to view therapist details.
   */
  VIEW_THERAPIST_DETAILS,
  /**
   * Therapists Management:
   * Permission to view therapist assistants.
   */
  VIEW_THERAPIST_ASSISTANTS,
  /**
   * Therapists Management:
   * Permission to manage therapist details.
   */
  MANAGE_THERAPIST_DETAILS,
  /**
   * Therapists Management:
   * Permission to soft delete a therapist profile.
   */
  SOFT_DELETE_THERAPIST_PROFILE,
  /**
   * Service Roles Management:
   * Permission to view the list of service roles.
   */
  VIEW_LIST_OF_SERVICE_ROLES,
  /**
   * Service Roles Management:
   * Permission to create a service role.
   */
  CREATE_SERVICE_ROLE,
  /**
   * Service Roles Management:
   * Permission to soft delete a service role.
   */
  SOFT_DELETE_SERVICE_ROLE,
  /**
   * Client Management:
   * Permission to create a client profile.
   */
  CREATE_CLIENT,
  /**
   * Client Management:
   * Permission to create a guardian profile.
   */
  CREATE_GUARDIAN,
  /**
   * Client Management:
   * Permission to view all clients.
   */
  VIEW_ALL_CLIENTS,
  /**
   * Client Management:
   * Permission to view client details.
   */
  EDIT_CLIENT,
  /**
   * Client Management:
   * Permission to delete a client profile.
   */
  DELETE_CLIENT,
  /**
   * Client Management:
   * Permission to view guardians list.
   */
  VIEW_ALL_GUARDIANS,
  /**
   * Client Management:
   * Permission to view guardian details.
   */
  VIEW_GUARDIAN_DETAILS,
  /**
   * Client Management:
   * Permission to edit guardian details.
   */
  EDIT_GUARDIAN,
  /**
   * Client Management:
   * Permission to delete a guardian profile.
   */
  DELETE_GUARDIAN,
  /**
   * Service Management:
   * Permission to create a service.
   */
  CREATE_SERVICE,
  /**
   * Service Management:
   * Permission to decline services.
   */
  DECLINE_SERVICE,
  /**
   * Service Management:
   * Permission to view all services.
   */
  VIEW_ALL_SERVICES,
  /**
   * Service Management:
   * Permission to view service details.
   */
  VIEW_SERVICE_DETAILS,
  /**
   * Service Management:
   * Permission to edit service details.
   */
  EDIT_SERVICE,
  /**
   * Service Management:
   * Permission to soft delete a service.
   */
  SOFT_DELETE_SERVICE,
  /**
   * Document Management:
   * Permission to view all documents.
   */
  VIEW_ALL_DOCUMENTS,
  /**
   * Document Management:
   * Permission to view my documents.
   */
  VIEW_MY_DOCUMENTS,
  /**
   * Document Management:
   * Permission to view document details.
   */
  VIEW_DOCUMENT_IN_THERAPIST,
  /**
   * Document Management:
   * Permission to manage document details.
   */
  MANAGE_DOCUMENT_DETAILS,
  /**
   * Document Management:
   * Permission to submit document.
   */
  SUBMIT_DOCUMENT,
  /**
   * Document Management:
   * Permission to pre submit document for therapist.
   */
  EDIT_DOCUMENT,
  /**
   * Document Management:
   * Permission to edit document.
   */
  PRESUBMIT_DOCUMENT,
  /**
   * Document Submit:
   * Permission to submit doc.
   */
  CHANGE_REPORT_FILE,
  /**
   * Document Management:
   * Permission to change the report file notes.
   */
  CHANGE_REPORT_FILE_NOTES,
  /**
   * Document Management:
   * Permission to approve or reject a report.
   */
  APPROVE_OR_REJECT_REPORT,
  /**
   * Document Management:
   * Permission to manually create a document.
   */
  MANUAL_CREATION_OF_DOCUMENT,
  /**
   * Document Management:
   * Permission to soft delete a document.
   */
  SOFT_DELETE_DOCUMENT,
  /**
   * Schedule Management:
   * Permission to view the full schedule.
   */
  VIEW_FULL_SCHEDULE,
  /**
   * Schedule Management:
   * Permission to view my schedule.
   */
  MY_SCHEDULE,
  /**
   * Therapist profile:
   * Permission to view ADP ID.
   */
  VIEW_ADP_ID,
  /**
   * Schedule Management:
   * Permission to add shifts to the schedule.
   */
  VIEW_SIGNATURES,
  /**
   * Signatures:
   * View signatures.
   */
  ADD_SHIFTS,
  PREVIEW_THERAPIST_PROFILE,
  /**
   * Edit clock in and clock out:
   * Permission to change clock in and clock out.
   */
  EDIT_SHIFTS_CLOCK_IN_CLOCK_OUT,
  ADD_SHIFTS_LIKE_ADMINS,

  ADD_SHIFTS_LIKE_NOT_ADMINS,
  /**
   * Schedule Management:
   * Permission to change shift details.
   */
  CHANGE_SHIFT_DETAILS,
  CHANGE_SHIFT_NOTES,
  /**
   * Schedule Management:
   * Permission to trigger SMS for guardian with link to sign shift one more time.
   */
  TRIGGER_SMS_FOR_SIGN_SHIFT,
  /**
   * Schedule Management:
   * Permission to change shift details with statuses COMPLETED OR NO_SHOW.
   */
  CHANGE_SHIFT_DETAILS_COMPLETED_NO_SHOW,
  /**
   * Schedule Management:
   * Permission to soft delete a shift.
   */
  SOFT_DELETE_SHIFT,
  /**
   * Schedule Management:
   * Permission to soft delete a shift with status NO SHOW.
   */
  SOFT_DELETE_SHIFT_NO_SHOW,
  /**
   * Schedule Management:
   * Permission to cancel a shift.
   */
  CANCEL_SHIFT,
  /**
   * Schedule Management:
   * Permission to cancel a shift with status COMPLETED.
   */
  CANCEL_COMPLETED_SHIFT,
  /**
   * Allow view cancel shifts:
   */
  VIEW_CANCEL_SHIFTS,
  /**
   * Chats Management:
   * Permission to manage department chats.
   */
  DEPARTMENTS_MANAGE,
  /**
   * Chats Management:
   * Permission to create a chat group.
   */
  CREATE_CHAT_GROUP,
  /**
   * Chats Management:
   * Permission to edit a chat group.
   */
  EDIT_CHAT_GROUP,
  /**
   * Chats Management:
   * Permission to delete a chat group.
   */
  DELETE_CHAT_GROUP,
  /**
   * Activity Log Management:
   * Permission to view activity logs.
   */
  VIEW_ACTIVITY_LOGS,
  /**
   * Activity Log Management:
   * Permission to view activity log details.
   */
  VIEW_ACTIVITY_LOG_DETAILS,
  /**
   * Timesheets Management:
   * Permission to view the reports tables.
   */
  VIEW_REPORTS,
  /**
   * Timesheets Management:
   * Permission to view the list of timesheets.
   */
  VIEW_TIMESHEETS_LIST,
  /**
   * Timesheets Management:
   * Permission to view timesheet details.
   */
  VIEW_TIMESHEET_DETAILS,
  /**
   * Timesheets Management:
   * Permission to enable automatic creation of timesheets.
   */
  AUTO_CREATION_OF_TIMESHEET,
  /**
   * Timesheets Management:
   * Permission to refresh timesheet calculations.
   */
  REFRESH_CALCULATION,
  /**
   * Timesheets Management:
   * Permission to approve a timesheet.
   */
  APPROVE_TIMESHEET,
  /**
   * Available Clients Management:
   * Permission to view the list of available clients.
   */
  AVAILABLE_CLIENTS_LIST,
  /**
   * Available Clients Management:
   * Permission to view available client details.
   */
  AVAILABLE_CLIENTS_DETAILS,
  /**
   * Available Clients Management:
   * Permission to view my list of clients.
   */
  MY_CLIENTS_LIST,
  /**
   * Available Clients Management:
   * Permission to view my client's details.
   */
  VIEW_MY_CLIENT_DETAILS,
  /**
   * Available Clients Management:
   * Permission to create an unpublished shift for contacting parents.
   */
  CREATE_UNPUBLISHED_SHIFT_FOR_CONTACTING_PARENTS,
  /**
   * Available Clients Management:
   * Permission to authorize the service.
   */
  AUTHORIZATION_OF_THE_SERVICE,
  /**
   * Available Clients Management:
   * Permission to return a client.
   */
  RETURN_CLIENT,
  /**
   * Available Clients Management:
   * Permission to manage on-hold services.
   */
  MANAGE_ON_HOLD_SERVICES,
  /**
   * Dashboards:
   * Permission to access the admin dashboard.
   */
  ADMIN_DASHBOARD,
  /**
   * Dashboards:
   * Permission to access the therapist dashboard.
   */
  THERAPIST_DASHBOARD,

  /**
   * View filter for get archived items:
   */
  VIEW_FILTER_ARCHIVED,
  /**
   * View my shifts:
   */
  VIEW_MY_SHIFTS,
}
