import { Dialog } from '@/components/ui';
import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { DialogDescription } from '@radix-ui/react-dialog';

import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { AddShiftForAdminsForm } from './add-shift-for-admins-form';
import { useIsAllowed } from '@/lib/RBAC';
import { PermissionsTypes } from '@/lib/RBAC/enums/permissions-types';
import { AddShiftForNotAdminsForm } from './add-shift-for-not-admins-form';
import { Shift } from '../interfaces/shift.interface';
import { EditShiftForm } from './edit-shift-form';
import { MyClient } from '@/views/my-clients/interfaces/my-clients.interface';

interface Props {
  onUpdated?: () => void;
}

export type ManageShiftDialogRef = {
  open: (shift?: Shift, service?: MyClient) => void;
  openEditNotes: (shift: Shift) => void;
};

const ManageShiftDialog = forwardRef<ManageShiftDialogRef, Props>(({ onUpdated }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [shift, setShift] = useState<Shift>();
  const [service, setService] = useState<MyClient>();
  const [isOnlyNotes, setIsOnlyNotes] = useState(false);
  const { checkPermissions } = useIsAllowed();
  const t = useTranslations();

  useImperativeHandle(ref, () => ({
    open: (shift?: Shift, service?: MyClient) => {
      setIsOpen(true);
      setIsOnlyNotes(false);
      if (shift) {
        setShift(shift);
      }
      if (service) {
        setService(service);
      }
    },
    openEditNotes: (shift: Shift) => {
      setIsOpen(true);
      setIsOnlyNotes(true);
      setShift(shift);
    }
  }));

  const handleAction = () => {
    setIsOpen(false);
    onUpdated?.();
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-[1280px]">
          <DialogHeader>
            <DialogTitle>{shift ? t('Pages.AllShifts.editShift') : t('Pages.AllShifts.addShift')} </DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <div className="pt-4">
            {shift ? (
              <EditShiftForm editOnlyNotes={isOnlyNotes} shift={shift} onCancel={() => setIsOpen(false)} onRefresh={handleAction} />
            ) : (
              <>
                {checkPermissions([PermissionsTypes.ADD_SHIFTS_LIKE_ADMINS]) && (
                  <AddShiftForAdminsForm service={service} onCancel={() => setIsOpen(false)} onRefresh={handleAction} />
                )}
                {checkPermissions([PermissionsTypes.ADD_SHIFTS_LIKE_NOT_ADMINS]) && (
                  <AddShiftForNotAdminsForm service={service} onCancel={() => setIsOpen(false)} onRefresh={handleAction} />
                )}
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
});

ManageShiftDialog.displayName = 'ManageShiftDialog';

export { ManageShiftDialog };
