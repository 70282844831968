import { ColumnDef, Row } from '@tanstack/react-table';
import { Trash2 } from 'lucide-react';
import { ServiceRole } from '../interfaces/service-role.interface';
import { SPECIALITY_CODES } from '@/common/enums';
import { Chips } from '@/components/common';
import { SPECIALITY_COLORS } from '@/common/constants/chips-colors';

type Params = {
  t: any;
  onConfirmRemove: (row: Row<ServiceRole>) => void;
  isCanRemove?: boolean;
};

export const getServiceRolesColumns = ({
  t,
  onConfirmRemove,
  isCanRemove = true,
}: Params): ColumnDef<ServiceRole>[] => {
  const columns: ColumnDef<ServiceRole>[] = [
    {
      id: 'Specialty',
      accessorKey: 'specialty',
      header: () => <span>{t('Tables.specialty')}</span>,
      cell: ({ row }) => {
        const color = SPECIALITY_COLORS[row.original.speciality.code as SPECIALITY_CODES];
        if (!color) {
          console.error(`DEBUG LOG: NEED SETUP COLOR FOR CODE (${row.original.speciality.code})`);
        }
        return <Chips color={color} title={row.original.speciality.code} />;
      },
      size: 50,
      enablePinning: false
    },
    {
      id: 'Location',
      header: () => <span>{t('Tables.location')}</span>,
      cell: ({ row }) => <span>{row.original.location.name}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Type',
      accessorKey: 'jobRoleType',
      enablePinning: false,
      header: () => <span>{t('Tables.type')}</span>,
      cell: ({ row }) => {
        const color = SPECIALITY_COLORS[row.original.speciality.code as SPECIALITY_CODES];
        if (!color) {
          console.error(`DEBUG LOG: NEED SETUP COLOR FOR CODE (${row.original.speciality.code})`);
        }
        return <Chips color={color} title={row.original.jobRoleType.name} />;
      },
      size: 140,
    },
    {
      id: 'Regional Center',
      accessorKey: 'regionalCenter',
      enablePinning: false,
      header: () => <span>{t('Tables.regionalCenter')}</span>,
      size: 140,
    },
  ];

  if (isCanRemove) {
    columns.push({
      id: 'Remove',
      enableSorting: false,
      cell: ({ row }) => (
        <Trash2
          onClick={(e) => {
            onConfirmRemove(row);
            e.stopPropagation();
          }}
          className="w-[18px] cursor-pointer text-gray-400"
        />
      ),
      size: 50,
      maxSize: 50,
    });
  }
  return columns;
};
