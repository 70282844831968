'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@/components/ui';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { REGIONAL_CENTERS } from '@/common/constants';
import { DOCUMENT_STATUS_TYPES, DOCUMENT_STATUSES, DOCUMENT_TYPES } from '@/common/constants/common';
import { SPECIALITY_CODES, SPECIALITY_CODES_ARRAY } from '@/common/enums';

export interface FilterAllDocumentsParams {
  regionalCenter?: string;
  search?: string;
  status?: string;
  reportType?: string;
  specialty?: SPECIALITY_CODES;
  dueDate?: 'Last month' | 'This month' | 'Next month';
}

interface Props {
  value?: FilterAllDocumentsParams;
  onApply: (value: FilterAllDocumentsParams) => void;
  onSaveAsDefault: (value: FilterAllDocumentsParams) => void;
  onCancel: () => void;
}

export function AllDocumentsFilterForm({ value, onApply, onSaveAsDefault, onCancel }: Props) {
  const t = useTranslations();

  const form = useForm<FilterAllDocumentsParams>({
    defaultValues: {
      regionalCenter: value ? value.regionalCenter : '',
      status: value ? value.status : '',
      reportType: value ? value.reportType : '',
      specialty: value?.specialty,
      dueDate: value?.dueDate,
    },
  });

  const onSubmit: SubmitHandler<FilterAllDocumentsParams> = (data: FilterAllDocumentsParams): void => {
    onApply(data);
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <div className="pl-1 pr-4">
          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="regionalCenter"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.regionalCenter.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.regionalCenter.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {REGIONAL_CENTERS.map((rc) => (
                          <SelectItem key={rc} value={rc}>
                            {rc}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="status"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.status.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.status.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {/* @TODO: Change Past due to Expired on BE */}
                        <SelectItem value={DOCUMENT_STATUS_TYPES.Completed}>Completed</SelectItem>
                        <SelectItem value={DOCUMENT_STATUS_TYPES.Pending}>Pending</SelectItem>
                        <SelectItem value={DOCUMENT_STATUS_TYPES.Submitted}>Submitted</SelectItem>
                        <SelectItem value={DOCUMENT_STATUS_TYPES.Expired}>Expired</SelectItem>
                        {/* {DOCUMENT_STATUSES.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))} */}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>


          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="reportType"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.reportType.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.reportType.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {DOCUMENT_TYPES.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="specialty"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.specialty.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.specialty.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        {SPECIALITY_CODES_ARRAY.map((item) => (
                          <SelectItem key={item} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative mb-2">
            <FormField
              control={form.control}
              name="dueDate"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>{t('Forms.dueDate.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.dueDate.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={null as any}>None</SelectItem>
                        <SelectItem value={'Last month'}>Last month</SelectItem>
                        <SelectItem value={'This month'}>This month</SelectItem>
                        <SelectItem value={'Next month'}>Next month</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

        </div>

        <footer className="flex flex-row items-center justify-end space-x-2">
          <Button onClick={onCancel} variant="outline" type="button" size="sm" className="mt-8">
            {t('Buttons.cancel')}
          </Button>
          <Button
            onClick={() => {
              onSaveAsDefault(form.getValues());
            }}
            type="button"
            variant="outline"
            size="sm"
            className="mt-8"
          >
            {t('Buttons.save')}
          </Button>
          <Button type="submit" size="sm" className="mt-8">
            {t('Buttons.apply')}
          </Button>
        </footer>
      </form>
    </Form>
  );
}
