'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Input,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  PhoneInput,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  TagsSelector,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui';
import { ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import { useUpdateTherapistMutation } from '@/hooks/fetchers/mutations/therapists/useUpdateTherapistMutation';
import { ServiceRole, Supervisor, Therapist } from '../interfaces/therapists.interface';
import { createRef, useEffect, useState } from 'react';
import { DialogFooter } from '@/components/ui/dialog';
import { useSupervisorsQuery } from '@/hooks/fetchers/queries/therapists/useSupervisorsQuery';

import { RoleLevels, useRolesQuery } from '@/hooks/fetchers/queries/useRolesQuery';
import { TagItem } from '@/components/form/tags-selector/tags-selector';
import { useServiceRolesQuery } from '@/hooks/fetchers/queries/service-roles/useServiceRolesQuery';
import { LANGUAGES_TAGS } from '@/common/constants';
import {
  manageTherapistValidationSchema,
  ManageTherapistValidationSchema,
} from '../manage/manage-therapist-validation-schema';
import { PHONE_COUNTRIES } from '@/common/constants/common';
import { ChooseAdpIdDialog, ChooseAdpIdDialogRef } from './choose-adp-id.dialog';
import { Plus } from 'lucide-react';

const LANGUAGES = LANGUAGES_TAGS();
interface Props {
  account: Therapist;
  onDelete: (value: ManageTherapistValidationSchema) => void;
  onUpdated: () => void;
}

export function UpdateTherapistForm({ onDelete, onUpdated, account }: Props) {
  const t = useTranslations();
  const [keywordServiceRole, setKeywordServiceRole] = useState('');
  const [keywordSupervisor, setKeywordSupervisor] = useState('');
  const [adpData, setAdpData] = useState<{ name: string | null; id: string } | null>(
    account.adpId ? { name: null, id: account.adpId } : null
  );
  const chooseAdpIdDialogRef = createRef<ChooseAdpIdDialogRef>();

  const {
    data: roles,
    refetch: refetchRoles,
    isLoading: isLoadingRolesIds,
  } = useRolesQuery(RoleLevels.THERAPIST, { enabled: false });

  const { data: supervisors, isLoading: isLoadingSupervisors } = useSupervisorsQuery<TagItem[]>(
    {
      search: keywordSupervisor,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.map((item: Supervisor) => ({ label: `${item.firstName} ${item.lastName}`, value: item })),
    }
  );

  const { data: serviceRoles, isLoading: isLoadingServiceRoles } = useServiceRolesQuery<TagItem[]>(
    {
      search: keywordServiceRole,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((serviceRole: ServiceRole) => ({ label: serviceRole.title, value: serviceRole })),
    }
  );

  const { mutate: update, isPending } = useUpdateTherapistMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.therapistUpdated'),
      });
      onUpdated();
    },
  });

  useEffect(() => {
    refetchRoles();
  }, []);

  const onSearchByServiceRoles = (value: string) => {
    setKeywordServiceRole(value);
  };

  const onSearchBySupervisor = (value: string) => {
    setKeywordSupervisor(value);
  };

  const form = useForm<ManageTherapistValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(manageTherapistValidationSchema),
    defaultValues: {
      firstName: account.firstName ?? '',
      lastName: account.lastName ?? '',
      email: account.email ?? '',
      phoneNumber: account.phone ?? '',
      supervisor: account.supervisor
        ? JSON.stringify({
            email: account.supervisor.email,
            firstName: account.supervisor.firstName,
            id: account.supervisor.id,
            lastName: account.supervisor.lastName,
          })
        : '',
      role: account.role ?? '',
      serviceRoleIdList: account.serviceRole
        ? account.serviceRole.map((sr: ServiceRole) => ({ label: sr.title, value: sr }))
        : [],
      languages: account.languages
        ? account.languages.map((lang: string) => ({ label: lang, value: { id: lang } }))
        : [],
    },
  });

  const onSubmit: SubmitHandler<ManageTherapistValidationSchema> = async (value: ManageTherapistValidationSchema) => {
    // if (adpData) {
    update({
      ...value,
      id: account.id,
      adpId: adpData?.id ?? '',
    });
    // } else {
    //   toast({
    //     title: t('Toasts.warning'),
    //     typeIcon: ToastTypeEnums.WARNING,
    //     description: t('Toasts.adpIdIsRequired'),
    //   });
    // }
  };

  const handleDelete = () => {
    onDelete(form.getValues());
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <ScrollArea className="h-[calc(100vh-364px)] overflow-auto">
          <div className="ml-1 grid grid-cols-form-cols-2 gap-8">
            <div className="relative">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.firstName.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        placeholder={t('Forms.firstName.placeholder')}
                        {...field}
                        type="text"
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="lastName"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.lastName.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        {...field}
                        type="text"
                        placeholder={t('Forms.lastName.placeholder')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="email"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.email.label')}</FormLabel>
                    <FormControl>
                      <Input
                        hasError={!!fieldState.error}
                        className="w-full"
                        placeholder={t('Forms.email.placeholder')}
                        {...field}
                        type="text"
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="phoneNumber"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.phoneNumber.label')}</FormLabel>
                    <FormControl>
                      <PhoneInput
                        countries={PHONE_COUNTRIES}
                        placeholder={t('Forms.phoneNumber.placeholder')}
                        {...field}
                        className="w-full"
                        hasError={!!fieldState.error}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="languages"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.languages.label')}</FormLabel>
                    <FormControl>
                      <TagsSelector
                        data={LANGUAGES}
                        values={(field.value ? field.value : []) as TagItem[]}
                        onChoose={field.onChange}
                        placeholder={t('Forms.languages.placeholder')}
                        className={cn('', !!fieldState.error && 'border-destructive')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="role"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.accessLevel.label')}</FormLabel>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue
                              placeholder={
                                isLoadingRolesIds ? t('Common.loadingWait') : t('Forms.accessLevel.placeholder')
                              }
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {roles?.map((role) => (
                            <SelectItem key={role.id} value={role.name}>
                              {role.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="serviceRoleIdList"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.serviceRole.label')}</FormLabel>
                    <FormControl>
                      <TagsSelector
                        search
                        data={serviceRoles ?? []}
                        onOpen={() => setKeywordServiceRole('')}
                        onChangeSearch={onSearchByServiceRoles}
                        values={field.value ? (field.value as TagItem[]) : []}
                        onChoose={field.onChange}
                        placeholder={
                          isLoadingServiceRoles ? t('Common.loadingWait') : t('Forms.serviceRole.placeholderSelector')
                        }
                        className={cn('', !!fieldState.error && 'border-destructive')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="supervisor"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.supervisor.label')}</FormLabel>
                    <FormControl>
                      <Select
                        onOpenChange={() => setKeywordSupervisor('')}
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue
                              placeholder={
                                isLoadingSupervisors ? t('Common.loadingWait') : t('Forms.supervisor.placeholder')
                              }
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent search onChangeSearch={onSearchBySupervisor}>
                          <SelectItem value={null as any}>None</SelectItem>
                          {field.value && field.value !== '' ? (
                            <SelectItem
                              value={field.value}
                            >{`${JSON.parse(field.value).firstName} ${JSON.parse(field.value).lastName}`}</SelectItem>
                          ) : null}

                          {supervisors
                            ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                            .map((item) => (
                              <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                                {item.label}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormItem>
                <FormLabel>{t('Forms.adpId.label')}</FormLabel>
                <FormControl>
                  <>
                    <span className="px-2 text-xs text-gray-500">({adpData && adpData.name ? adpData.name : account.adpEmployeeName})</span>
                    <div className="flex h-10 w-full flex-row items-center justify-between rounded-sm border border-gray-300 px-2">
                      <span className="w-full">{adpData ? adpData.id : '---'}</span>
                      <Button
                        type="button"
                        variant="ghost"
                        size="icon"
                        onClick={() => chooseAdpIdDialogRef.current?.open()}
                      >
                        <Plus />
                      </Button>
                    </div>
                  </>
                </FormControl>
              </FormItem>
            </div>
          </div>
        </ScrollArea>
        <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
          <Button onClick={handleDelete} variant="outlineDestructive" type="button" size="lg" className="mt-5">
            {t('Buttons.deleteTherapistAccount')}
          </Button>
          <Button type="submit" size="lg" className="mt-5" disabled={isPending}>
            {t('Buttons.save')}
          </Button>
        </DialogFooter>
      </form>
      <ChooseAdpIdDialog handleChoose={(value) => setAdpData(value)} ref={chooseAdpIdDialogRef} />
    </Form>
  );
}
