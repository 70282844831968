import { Dialog } from '@/components/ui';
import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { DialogDescription } from '@radix-ui/react-dialog';

import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Document } from '../interfaces/document.interface';
import { EditDocumentForm } from './edit-document-form';

interface Props {
  onUpdated?: () => void;
}

export type EditDocumentDialogRef = {
  open: (document: Document) => void;
};

const EditDocumentDialog = forwardRef<EditDocumentDialogRef, Props>(({ onUpdated }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [document, setDocument] = useState<Document>();
  const t = useTranslations();

  useImperativeHandle(ref, () => ({
    open: (document: Document) => {
      setIsOpen(true);
      if (document) {
        setDocument(document);
      }
    },
  }));

  const handleAction = () => {
    setIsOpen(false);
    onUpdated?.();
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-[1280px]">
          <DialogHeader>
            <DialogTitle>{t('Pages.AllDocuments.editDocument')} </DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <div className="pt-4">
            {document && <EditDocumentForm document={document} onCancel={() => setIsOpen(false)} onUpdate={handleAction} />}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
});

EditDocumentDialog.displayName = 'EditDocumentDialog';

export { EditDocumentDialog };
