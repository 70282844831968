import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { TimesheetPayroll } from '@/views/timesheet-details/interfaces/timesheet-payroll.interface';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as _ from 'lodash';

type Response = {
  data: TimesheetPayroll[];
  totalCount: number;
};
interface Params {
  id: string;
  skip: number;
  take: number;
  search?: string;
  sortField?: string | null;
  sortOrder?: 'ASC' | 'DESC';
}

export type Options = Omit<UseQueryOptions<unknown, DefaultError, Response>, 'queryKey' | 'queryFn'>;

export const useTimesheetPayrollsQuery = (params: Params, options?: Options) => {
  const queryParams = {
    skip: params.skip,
    take: params.take,
    search: params.search,
    sortField: params.sortField, 
    sortOrder: params.sortField ? params.sortOrder : null,
  };

  const cleanedQueryParams = _.omitBy(queryParams, value => _.isNil(value));

  const _query = async (): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.get(`timesheets/${params.id}/payrolls`, {
      params: cleanedQueryParams
    });
    return data;
  };

  return useQuery({
    queryKey: ['timesheets', params.id, queryParams],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
