import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { TypesOfShiftRepeat } from '@/common/enums/common';
import { EditShiftValidationSchema } from '@/views/all-shifts/manage/edit-shift-validation-schema';
import { RecurringShiftData } from '@/views/all-shifts/manage/recurring-shift-popover';
import { RecurringShiftValidationSchema } from '@/views/all-shifts/manage/recurring-shift-validation-schema';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from '@/lib/dayjsConfig';
import * as _ from 'lodash';

export interface Request extends RecurringShiftValidationSchema, EditShiftValidationSchema, RecurringShiftData {
  id: string;
}

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useUpdateShiftMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: Request): Promise<string> => {
    const { notes, date, endTime, startTime, clockIn, clockOut } = value;

    const shiftOffset = dayjs(date).utcOffset();
    const currentOffset = dayjs().utcOffset();
    const offsetDifference = currentOffset - shiftOffset;

    const parsedValue = {
      startAt: dayjs(startTime, 'hh:mm A').set('date', date.date()).set('month', date.month()).set('year', date.year()).subtract(offsetDifference, 'minute').toISOString(),
      endAt: dayjs(endTime, 'hh:mm A').set('date', date.date()).set('month', date.month()).set('year', date.year()).subtract(offsetDifference, 'minute').toISOString(),
      clockIn: clockIn ? dayjs(clockIn.toUpperCase(), 'hh:mm A').set('date', date.date()).set('month', date.month()).set('year', date.year()).subtract(offsetDifference, 'minute').toISOString() : null,
      clockOut: clockOut ? dayjs(clockOut.toUpperCase(), 'hh:mm A').set('date', date.date()).set('month', date.month()).set('year', date.year()).subtract(offsetDifference, 'minute').toISOString() : null,
      notes: notes && notes !== '' ? notes : null,
      recurrenceType: value.configureRepeat,
      weekOrMonthDay:
      value.configureRepeat === TypesOfShiftRepeat.SINGLE
          ? 0
          : value.configureRepeat === TypesOfShiftRepeat.WEEKLY
            ? value.repeatOnWeek
            : parseInt(value.repeatOnDay as string),
      lastRepeatDate: value.endsOn,
    }
    const body = _.omitBy(parsedValue, v => v === '');
    const { data } = await axiosInterceptorInstance.patch(`shifts/${value.id}`, body);
    return data;
  };

  return useMutation({
    mutationKey: ['shifts'],
    mutationFn: _mutation,
    onSettled: ((data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['services'] });
        queryClient.invalidateQueries({ queryKey: ['clients'] });
        queryClient.invalidateQueries({ queryKey: ['dashboard'] });
        queryClient.invalidateQueries({ queryKey: ['therapists'] });
        queryClient.invalidateQueries({ queryKey: ['shifts'] });
      }
    }),
    ...options,
  });
};
