'use client';
import { MenubarItem } from '@/components/ui/menubar';
import { cn } from '@/lib/utils';
import { Link, usePathname } from '@/navigation';

interface Params {
  link: {
    href: string;
    text: string;
  };
  className?: string;
}

export function HeaderNavMenuItem({ link, className }: Params) {
  const pathname = usePathname();

  return (
    <MenubarItem asChild>
      <Link
        className={cn(
          'cursor-pointer w-full text-sm font-medium text-gray-400 transition-colors duration-300 hover:text-primary',
          className,
          pathname === link.href && 'border-primary text-primary'
        )}
        href={link.href}
      >
        {link.text}
      </Link>
    </MenubarItem>
  );
}
