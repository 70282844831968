import { ColumnDef, Row } from '@tanstack/react-table';
import { toMaskByString } from '@/components/form/phone-input/utils';
import { cn } from '@/lib/utils';
import { Admin } from '../interfaces/admins.interface';
import { Trash2 } from 'lucide-react';
import { Chips } from '@/components/common';
import { Colors } from '@/common/enums';
import { USER_ROLES_COLORS } from '@/common/constants/chips-colors';
import { RoleTypes } from '@/lib/RBAC/enums/role-types';

type Params = {
  t: any;
  onConfirmRemove: (row: Row<Admin>) => void;
  isCanRemove?: boolean;
};

export const getAdminUsersListColumns = ({ t, onConfirmRemove, isCanRemove = true }: Params): ColumnDef<Admin>[] => {
  const columns: ColumnDef<Admin>[] = [
    {
      id: 'Full Name',
      accessorFn: (row) => `${row.firstName || ''} ${row.lastName || ''}`,
      header: () => <span>{t('Tables.fullName')}</span>,
      size: 250, //starting column size
      enablePinning: false
    },
    {
      id: 'Email',
      accessorKey: 'email',
      header: () => <span>{t('Tables.email')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Phone Number',
      accessorFn: (row) => toMaskByString(row.phone),
      header: () => <span>{t('Tables.phoneNumber')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 170,
    },
    {
      id: 'Access Level',
      header: () => <span>{t('Tables.accessLevel')}</span>,
      enableSorting: false,
      enablePinning: false,
      cell: ({ row }) => {
        if (row.original.role) {
          return <Chips color={USER_ROLES_COLORS[RoleTypes.Admin]} title={row.original.role} />;
        } else {
          return <span></span>;
        }
      },
      size: 250,
    },
  ];

  if (isCanRemove) {
    columns.push({
      id: 'Remove',
      enableSorting: false,
      cell: ({ row }) => (
        <Trash2
          onClick={(e) => {
            onConfirmRemove(row);
            e.stopPropagation();
          }}
          className="w-[18px] cursor-pointer text-gray-400"
        />
      ),
      size: 50,
    });
  }
  return columns;
};
