export enum SPECIALITY_CODES {
  OT = 'OT',
  PT = 'PT',
  SLP = 'SLP',
  CDS = 'CDS',
  FSS = 'FSS',
  INT = 'INT',
}

export const SPECIALITY_CODES_ARRAY = ['OT', 'PT', 'SLP', 'CDS', 'FSS', 'INT'];
