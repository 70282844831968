import { z } from 'zod';
import dayjs, { Dayjs } from 'dayjs';
import { TIME_12h } from '@/common/constants';

export const editShiftValidationSchema = z
  .object({
    date: z.custom<Dayjs>(
      (value) => {
        return dayjs.isDayjs(value);
      },
      { message: 'Forms.endsOn.required' }
    ),
    startTime: z.string().min(1, { message: 'Forms.startTime.required' }),
    endTime: z.string().min(1, { message: 'Forms.endTime.required' }),
    clockIn: z.custom<string>(
      (value) => {
        if (value.length !== 0) {
          return TIME_12h.test(value.toUpperCase());
        }
        return true;
      },
      { message: 'Forms.clockInTime.invalid' }
    ),
    clockOut: z.custom<string>(
      (value) => {
        if (value.length !== 0) {
          return TIME_12h.test(value.toUpperCase());
        }
        return true;
      },
      { message: 'Forms.clockOutTime.invalid' }
    ),
    notes: z.string().optional(),
  })

  .superRefine((data, ctx) => {
    if (data.startTime && data.endTime) {
      // Define the start and end times
      const startTime = dayjs(data.startTime, 'h:mm A');
      const endTime = dayjs(data.endTime, 'h:mm A');

      // Calculate the duration in minutes
      const timeDifference = endTime.diff(startTime, 'minute');

      // Create a duration of 1 hour 30 minutes (90 minutes)
      const maxDuration = dayjs.duration(90, 'minutes');
      // Create a duration of 15 minutes
      const minDuration = dayjs.duration(15, 'minutes');

      if (timeDifference < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['endTime'],
          message: 'Forms.endTime.invalid',
        });
      }

      // Check if the duration is less than 1 hour 30 minutes
      if (timeDifference > maxDuration.asMinutes()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['endTime'],
          message: 'Forms.endTime.max',
        });
      }

      // Check if the duration is more than 15 minutes
      if (timeDifference < minDuration.asMinutes()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['endTime'],
          message: 'Forms.endTime.min',
        });
      }
    }
  });

export type EditShiftValidationSchema = z.infer<typeof editShiftValidationSchema>;
