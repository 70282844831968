import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { TableTypes } from '@/common/enums/common';

export interface Response {
  name: TableTypes;
  filters: any;
}

export type Options = Omit<UseQueryOptions<unknown, DefaultError, Response>, 'queryKey' | 'queryFn'>;

export const useTableFilterDefaultQuery = (table: TableTypes, options?: Options) => {
  const _query = async (): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.get(`table-filters-configuration/${table}`);
    return data;
  };

  return useQuery({
    queryKey: ['table-filters-configuration', table],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
