'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@/components/ui';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  TagsSelector,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import {
  LANGUAGES,
  REGIONAL_CENTERS,
  SERVICE_ROLE_TYPES,
  SERVICE_ROLES,
} from '@/common/constants';
import { RoleLevels, useRolesQuery } from '@/hooks/fetchers/queries/useRolesQuery';
import { TagItem } from '@/components/form/tags-selector/tags-selector';
import { ScrollArea } from '@radix-ui/react-scroll-area';
import { Role } from '@/common/interfaces';

export interface FilterAllTherapistsParams {
  accessLevels?: TagItem[];
  serviceRoleType?: string;
  serviceRole?: string;
  regionalCenter?: string;
  language?: string;
  search?: string;
}

interface Props {
  value?: FilterAllTherapistsParams;
  onApply: (value: FilterAllTherapistsParams) => void;
  onSaveAsDefault: (value: FilterAllTherapistsParams) => void;
  onCancel: () => void;
}

export function AllTherapistsFilterForm({ value, onApply, onSaveAsDefault, onCancel }: Props) {
  const t = useTranslations();

  const form = useForm<FilterAllTherapistsParams>({
    defaultValues: {
      serviceRole: value ? value.serviceRole : '',
      serviceRoleType: value ? value.serviceRoleType : '',
      regionalCenter: value ? value.regionalCenter : '',
      accessLevels: value ? value.accessLevels : [],
      language: value ? value.language : '',
    },
  });

  const { data: rolesTags, isLoading: isLoadingRolesIds } = useRolesQuery<TagItem[]>(RoleLevels.THERAPIST, {
    select: (value: any): TagItem[] => value.map((role: Role) => ({ label: role.name, value: role })),
  });

  const onSubmit: SubmitHandler<FilterAllTherapistsParams> = (data: FilterAllTherapistsParams): void => {
    onApply(data);
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <ScrollArea className="h-[416px] overflow-auto">
          <div className="pl-1 pr-4">
            <div className="relative mb-2">
              <FormField
                control={form.control}
                name="serviceRole"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.setting.label')}</FormLabel>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue placeholder={t('Forms.setting.placeholderSelector')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value={null as any}>None</SelectItem>
                          {SERVICE_ROLES.map((rc) => (
                            <SelectItem key={rc} value={rc}>
                              {rc}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
            <div className="relative mb-2">
              <FormField
                control={form.control}
                name="serviceRoleType"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.serviceRoleType.label')}</FormLabel>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue placeholder={t('Forms.serviceRoleType.placeholderSelector')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value={null as any}>None</SelectItem>
                          {SERVICE_ROLE_TYPES.map((rc) => (
                            <SelectItem key={rc} value={rc}>
                              {rc}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative mb-2">
              <FormField
                control={form.control}
                name="regionalCenter"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.regionalCenter.label')}</FormLabel>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue placeholder={t('Forms.regionalCenter.placeholderSelector')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value={null as any}>None</SelectItem>
                          {REGIONAL_CENTERS.map((rc) => (
                            <SelectItem key={rc} value={rc}>
                              {rc}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative mb-2">
              <FormField
                control={form.control}
                name="accessLevels"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.accessLevel.label')}</FormLabel>
                    <FormControl>
                      <TagsSelector
                        data={rolesTags ?? []}
                        values={value ? value.accessLevels : []}
                        onChoose={field.onChange}
                        placeholder={isLoadingRolesIds ? t('Common.loadingWait') : t('Forms.accessLevel.placeholder')}
                        className={cn('', !!fieldState.error && 'border-destructive')}
                      />
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative mb-2">
              <FormField
                control={form.control}
                name="language"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.language.label')}</FormLabel>
                    <FormControl>
                      <Select value={field.value} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue placeholder={t('Forms.language.placeholder')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value={null as any}>None</SelectItem>
                          {LANGUAGES.map((lang, index) => (
                            <SelectItem key={`${lang}_${index}`} value={lang}>
                              {lang}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </ScrollArea>

        <footer className="flex flex-row items-center justify-end space-x-2">
          <Button onClick={onCancel} variant="outline" type="button" size="sm" className="mt-8">
            {t('Buttons.cancel')}
          </Button>
          <Button
            onClick={() => {
              onSaveAsDefault(form.getValues());
            }}
            type="button"
            variant="outline"
            size="sm"
            className="mt-8"
          >
            {t('Buttons.save')}
          </Button>
          <Button type="submit" size="sm" className="mt-8">
            {t('Buttons.apply')}
          </Button>
        </footer>
      </form>
    </Form>
  );
}
