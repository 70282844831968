'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  GooglePlacesInput,
  Input,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui';
import {
  manageServiceRoleValidationSchema,
  ManageServiceRoleValidationSchema,
} from './manage-service-role-validation-schema';
import { toast, ToastTypeEnums } from '@/components/common';
import { useCreateServiceRoleMutation } from '@/hooks/fetchers/mutations/service-roles/useCreateServiceRoleMutation';
import { REGIONAL_CENTERS } from '@/common/constants';

interface Props {
  onCancel: () => void;
  onUpdate: () => void;
}

export function ManageServiceRoleForm({ onCancel, onUpdate }: Props) {
  const t = useTranslations();

  const { mutate: create, isPending } = useCreateServiceRoleMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: data,
      });
      form.reset();
      onUpdate();
    },
  });

  const form = useForm<ManageServiceRoleValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(manageServiceRoleValidationSchema),
    defaultValues: {
      location: '',
      regionalCenter: '',
    },
  });

  const onSubmit: SubmitHandler<ManageServiceRoleValidationSchema> = (
    data: ManageServiceRoleValidationSchema
  ): void => {
    create(data);
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)} className="mt-8 flex h-full w-full flex-col gap-8">
        <div className="flex h-[calc(100vh-232px)] w-full flex-col gap-8">
          <div className="relative">
            <FormField
              control={form.control}
              name="location"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel isRequired>{t('Forms.location.label')}</FormLabel>
                  <FormControl>
                    <Input
                      hasError={!!fieldState.error}
                      className="w-full"
                      placeholder={t('Forms.location.placeholder')}
                      {...field}
                      type="text"
                    />
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>

          <div className="relative">
            <FormField
              control={form.control}
              name="regionalCenter"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel isRequired>{t('Forms.regionalCenter.label')}</FormLabel>
                  <FormControl>
                    <Select value={field.value} onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                          <SelectValue placeholder={t('Forms.regionalCenter.placeholderSelector')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {REGIONAL_CENTERS.map((rc) => (
                          <SelectItem key={rc} value={rc}>
                            {rc}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="absolute" />
                </FormItem>
              )}
            />
          </div>
        </div>
        <footer className="flex h-20 flex-row items-center justify-between space-x-2">
          <Button onClick={onCancel} variant="ghost" type="button" size="lg" className="w-full">
            {t('Buttons.cancel')}
          </Button>
          <Button type="submit" size="lg" className="w-full" disabled={isPending}>
            {t('Buttons.generate')}
          </Button>
        </footer>
      </form>
    </Form>
  );
}
