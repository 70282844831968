'use client';

import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/form';
import { useTranslations } from 'next-intl';
import { Button } from '@/components/ui';
import { ScrollArea, toast, ToastTypeEnums, UploadFileArea } from '@/components/common';
import { DialogFooter } from '@/components/ui/dialog';

import { TagItem } from '@/components/form/tags-selector/tags-selector';
import { useEffect, useState } from 'react';
import { DOCUMENT_STATUS_TYPES, DOCUMENT_TYPES } from '@/common/constants/common';
import { cn } from '@/lib/utils';
import { DatePicker } from '@/components/form/date-picker/date-picker';
import { useTherapistsQuery } from '@/hooks/fetchers/queries/therapists/useTherapistsQuery';
import { Therapist } from '@/views/all-therapists/interfaces/therapists.interface';
import { useServicesQuery } from '@/hooks/fetchers/queries/services/useServicesQuery';
import { Service } from '@/views/all-services/interfaces/services.interface';
import { FileText, X } from 'lucide-react';
import dayjs from '@/lib/dayjsConfig';
import { Document } from '../interfaces/document.interface';
import { useUpdateDocumentMutation } from '@/hooks/fetchers/mutations/documents/useUpdateDocumentMutation';
import { updateDocumentValidationSchema, UpdateDocumentValidationSchema } from './update-document-validation-schema';

interface Props {
  document: Document;
  onCancel: () => void;
  onUpdate: (document: any) => void;
}

export function EditDocumentForm({ document, onCancel, onUpdate }: Props) {
  const t = useTranslations();
  const [file, setFile] = useState<File | null>(null);
  const [keywordService, setKeywordService] = useState('');
  const [keywordTherapist, setKeywordTherapist] = useState('');

  const { data: therapists, isLoading: isLoadingTherapists } = useTherapistsQuery<TagItem[]>(
    {
      search: keywordTherapist,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((therapist: Therapist) => ({
          label: `${therapist.firstName} ${therapist.lastName}`,
          value: {
            id: therapist.id,
            firstName: therapist.firstName,
            lastName: therapist.lastName,
          },
        })),
    }
  );

  const { data: services, isLoading: isLoadingServices } = useServicesQuery<TagItem[]>(
    {
      search: keywordService,
      skip: 0,
      take: 200,
    },
    {
      enabled: true,
      select: (value: any): TagItem[] =>
        value.data.map((sr: Service) => ({
          label: sr.title,
          value: {
            id: sr.id,
            title: sr.title,
            serviceRole: sr.serviceRole,
            client: sr.client,
            authorizedTo: sr.authorizedTo,
            therapist: sr.therapist,
          },
        })),
    }
  );

  const { mutate: update, isPending: isCreating } = useUpdateDocumentMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.documentCreated'),
      });
      form.reset();
      onUpdate(data);
    },
  });

  const form = useForm<UpdateDocumentValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(updateDocumentValidationSchema),
    defaultValues: {
      therapist: document.therapist
        ? JSON.stringify({
            id: document.therapist.id,
            firstName: document.therapist.firstName,
            lastName: document.therapist.lastName,
          })
        : '',
      service: document.service
        ? JSON.stringify({
            id: document.service.id,
            title: document.service.name,
            serviceRole: document.service.serviceRole,
            client: document.client,
            authorizedTo: document.service.authorizedTo,
            therapist: document.therapist,
          })
        : '',
      dueDate: document && document.expiryDate ? dayjs(document?.expiryDate, 'YYYY-MM-DD') : '',
      status: document.status,
      documentType: document.type,
    },
  });

  const serviceSelector = form.watch('service');

  const onSubmit: SubmitHandler<UpdateDocumentValidationSchema> = async (
    value: UpdateDocumentValidationSchema
  ) => {
    update({ ...value, file, id: document.id });
  };

  useEffect(() => {
    const { service } = form.getValues();
    if (service) {
      const parsedService: Service = JSON.parse(service);
      if (parsedService.therapist) {
        const { id, firstName, lastName } = parsedService.therapist;
        form.setValue('therapist', JSON.stringify({id, firstName, lastName}));
      }
    }
  }, [serviceSelector]);


  const onSearchByServices = (value: string) => {
    setKeywordService(value);
  };

  const onSearchByTherapists = (value: string) => {
    setKeywordTherapist(value);
  };

  return (
    <Form {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <ScrollArea className="h-[calc(100vh-300px)] overflow-auto">
          <div className="ml-1 grid grid-cols-form-cols-2 gap-8">
            <div className="relative">
              <FormField
                control={form.control}
                name="service"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.service.label')}</FormLabel>
                    <FormControl>
                      <Select
                        onOpenChange={() => setKeywordService('')}
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue
                              placeholder={isLoadingServices ? t('Common.loadingWait') : t('Forms.service.placeholder')}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent search onChangeSearch={onSearchByServices}>
                          {field.value && <SelectItem value={field.value}>{JSON.parse(field.value).title}</SelectItem>}

                          {services
                            ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                            .map((item) => (
                              <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                                {item.label}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="therapist"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.therapist.label')}</FormLabel>
                    <FormControl>
                      <Select
                        onOpenChange={() => setKeywordTherapist('')}
                        value={field.value}
                        onValueChange={field.onChange}
                      >
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue
                              placeholder={
                                isLoadingTherapists ? t('Common.loadingWait') : t('Forms.therapist.placeholder')
                              }
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent search onChangeSearch={onSearchByTherapists}>
                          <SelectItem value={null as any}>None</SelectItem>
                          {field.value && field.value !== '' ? (
                            <SelectItem
                              value={field.value}
                            >{`${JSON.parse(field.value).firstName} ${JSON.parse(field.value).lastName}`}</SelectItem>
                          ) : null}

                          {therapists
                            ?.filter((item) => (field.value ? item.value.id !== JSON.parse(field.value).id : true))
                            .map((item) => (
                              <SelectItem key={item.value.id} value={JSON.stringify(item.value)}>
                                {item.label}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="dueDate"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>{t('Forms.dueDate.label')}</FormLabel>
                    <DatePicker isOptional disableDates="none" field={field} isError={fieldState.error} />
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="status"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.status.label')}</FormLabel>
                    <FormControl>
                      <Select value={field.value?.toString()} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue placeholder={t('Forms.status.placeholderSelector')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {/* @TODO: Change Past due to Expired on BE */}
                          <SelectItem value={DOCUMENT_STATUS_TYPES.Completed}>Completed</SelectItem>
                          <SelectItem value={DOCUMENT_STATUS_TYPES.Pending}>Pending</SelectItem>
                          <SelectItem value={DOCUMENT_STATUS_TYPES.Submitted}>Submitted</SelectItem>
                          <SelectItem value={DOCUMENT_STATUS_TYPES.Expired}>Expired</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>

            <div className="relative">
              <FormField
                control={form.control}
                name="documentType"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel isRequired>{t('Forms.documentType.label')}</FormLabel>
                    <FormControl>
                      <Select value={field.value?.toString()} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className={cn('', !!fieldState.error && 'border-destructive')}>
                            <SelectValue placeholder={t('Forms.documentType.placeholderSelector')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {DOCUMENT_TYPES.map((item) => (
                            <SelectItem key={item} value={item}>
                              {item}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage className="absolute" />
                  </FormItem>
                )}
              />
            </div>
          </div>

          {!file && (
            <UploadFileArea
              {...{
                accept: ['.pdf', '.docx', '.doc'],
                maxSizeMb: 10,
                allowTypes: ['pdf', 'PDF', 'doc', 'DOC', 'docx', 'DOCX'],
                maxSizeText: '10MB',
                typeText: 'PDF, DOC, DOCX',
              }}
              className="mt-8 h-56 w-96"
              onSelect={(file) => setFile(file)}
            />
          )}

          {file && (
            <div className="mt-4">
              <h4 className="mb-2 ml-1 select-none text-sm font-medium text-gray-700">
                {t('Forms.reportFile.label')} <span className="text-destructive">*</span>
              </h4>
              <div className="inline-flex flex-row items-center justify-start space-x-2 rounded-md border border-gray-300 bg-transparent px-3 py-1 shadow-sm">
                <FileText className="h-6 w-6 text-primary" />
                <span>{file.name}</span>
                <Button type="button" onClick={() => setFile(null)} variant="ghost" size="iconSm">
                  <X className="h-5 w-4" />
                </Button>
              </div>
            </div>
          )}
        </ScrollArea>

        <DialogFooter className="mt-10 flex h-20 flex-row items-center justify-end space-x-2 border-t border-t-gray-300">
          <Button onClick={onCancel} variant="ghost" type="button" size="lg" className="mt-5">
            {t('Buttons.cancel')}
          </Button>

          <Button type="submit" size="lg" className="mt-5" disabled={isCreating}>
            {t('Buttons.save')}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
