import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

type Request = {
  id: string;
  dueDate: string;
}

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useEditExpDateDocumentMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: Request): Promise<string> => {
    const { data } = await axiosInterceptorInstance.put(`documents/${value.id}/edit`, {
      dueDate: value.dueDate
    });
    return data;
  };

  return useMutation({
    mutationKey: ['documents', 'approve'],
    mutationFn: _mutation,
    onSettled: ((data, error) => {
      if (!error) {
      queryClient.invalidateQueries({ queryKey: ['documents'] });
      queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    }),
    ...options,
  });
};
